import React, { useEffect } from 'react'
import { navigate } from '@reach/router'

/**
 * Use inside router as default route to catch & redirect 404s
 * @param {string} to absolute path of page to redirect to
 */
const Redirect = ({ to }) => {
  useEffect(() => {
    navigate(to)
  }, [to])
  return <div>Redirecting...</div>
}
export default Redirect
