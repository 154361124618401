export default function (instance) {
  return {
    get(config = {}) {
      return instance.get('client/', config);
    },
    signup(body, config = {}) {
      return instance.post('client/signup/', body, config);
    },
    update(data) {
      return instance.put(`client/`, data);
    },
}
}
