import _ from 'lodash';

export default function (instance) {
  return {
    getAll(client_partner_id, config = {}) {
      return instance.get(`biz/partner/earn/client_microtasks/${client_partner_id}`, config);
    },
    async getActive(config = {}) {
      const response = await instance.get('earn/microtasks/', config);
      const active = _.get(response, 'active_microtasks', []);
      //filter by is_credited: false
      return active;
    },
    check(opportunity_id, config = {}) {
      return instance.get(`earn/microtasks/check/${opportunity_id}/`, config);
    },
    join(body, config = {}) {
      return instance.post('earn/microtasks/opt/', body, config);
    },
    submitRequirement(body, config = {}) {
      return instance.post('earn/microtasks/unshaped/submit/', body, config);
    },
    submitRepeatableRequirement(body, config = {}) {
      return instance.post('earn/microtasks/unshaped_repeatable/submit/', body, config);
    },
    submitTask(body, config = {}) {
      return instance.post('earn/microtasks/unshaped/submit/', body, config);
    },
  };
}
