import { Container } from "unstated";
import { USER_DATA } from "constants/userConstants";
import CookieStorage from "js-cookie";
import api from "api";
import moment from 'moment';

const DEFAULT_STATE = {
  bank_account: {},
  profile: {},
  profile_extended: {},
  location_details: {},
};

class User extends Container {
  constructor() {
    super();

    const { bank_account, profile, profile_extended, location_details } =
      CookieStorage.getJSON(USER_DATA) || DEFAULT_STATE;

    this.state = {
      bank_account,
      profile,
      profile_extended,
      location_details
    };
  }

  set = async (data = {}) => {
    const updatedState = {
      ...this.state,
      ...data,
    };

    const { my_uuid_id, partner_id, parent_partner_id, gender, email, name, surname } = updatedState.profile;

    await api.business.setPersonalDetails(parent_partner_id, partner_id, my_uuid_id, {
      gender: {
        value: gender,
        timestamp: moment().format()
      },
      first_name: {
        value: name,
        timestamp: moment().format()
      },
      last_name: {
        value: surname,
        timestamp: moment().format()
      },
      email: {
        value: email,
        timestamp: moment().format()
      },
    });


    return this.setState(updatedState, () => {
      CookieStorage.set(USER_DATA, updatedState);
    });
  };

  clear() {
    return this.setState(DEFAULT_STATE, () => {
      CookieStorage.set(USER_DATA, DEFAULT_STATE);
    });
  }
}

export default new User();
