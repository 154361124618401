import colours from "./colours";

const label = {
  fontSize: "0.875rem",
  fontWeight: 500,
  lineHeight: "110%",
  display: "flex",
  alignItems: "center",
  color: colours.grey2,
};

const input = {
  fontWeight: "500",
  fontSize: "1rem",
  lineHeight: "150%",
  letterSpacing: "0.01em",
  color: colours.grey2,
};

const inputPopulated = {
  ...input,
  color: colours.darkBlue,
  fontWeight: "bold",
};

const button = {
  fontWeight: "bold",
  fontSize: "1rem",
  color: colours.white,
};

const link = {
  fontWeight: "500",
  fontSize: "1rem",
  lineHeight: "150%",
  color: colours.grey2,
  textDecoration: "none",
};

const badge = {
  fontWeight: "bold",
  fontSize: "0.875rem",
  lineHeight: "150%",
  textAlign: "center",
  letterSpacing: "0.01em",
  color: colours.grey2,
};

const title = {
  fontWeight: "bold",
  fontSize: "3.75rem",
  color: colours.zltoDarkBlue,
  textAlign: "center",
};

const titleMobile = {
  fontWeight: "bold",
  fontSize: "2rem",
  color: colours.zltoDarkBlue,
};

const heading2 = {
  fontWeight: "bold",
  fontSize: "2.5rem",
  color: colours.darkBlue,
  lineHeight: "110%",
};

/* H3 bold 32 */
const heading3 = {
  fontWeight: "bold",
  fontSize: "2rem",
  color: colours.zltoDarkBlue,
  lineHeight: "130%",
};

/* H4 bold 30 */
const heading4 = {
  fontWeight: "bold",
  fontSize: "1.875rem",
  color: colours.zltoDarkBlue,
  lineHeight: "130%",
};

/* H5 bold 25 */
const heading5 = {
  fontWeight: "bold",
  fontSize: "1.5625rem",
  color: colours.zltoDarkBlue,
};

/* H6 bold 20 */
const heading6 = {
  fontWeight: "bold",
  fontSize: "1.25rem",
  letterSpacing: "0.01em",
  color: colours.zltoDarkBlue,
};

const p = {
  fontWeight: 500,
  lineHeight: "150%",
  letterSpacing: "0.01em",
  color: colours.grey2,
};

export const fonts = {
  label,
  button,
  link,
  badge,
  input,
  inputPopulated,
  title,
  titleMobile,
  heading2,
  heading3,
  heading4,
  heading5,
  heading6,
  p,
};
