const { NODE_ENV } = process.env;

export const ENV = {
  NODE_ENV,
  BASE_URL: process.env.REACT_APP_BASE_URL,
};

const missingKeys = ["NODE_ENV", "BASE_URL"].filter((key) => !ENV[key]);

if (missingKeys.length) {
  ENV.MISSING_CONFIG = missingKeys.join(", ");
}

export const isLocalHost = Boolean(
  window.location.hostname === "localhost" ||
    // [::1] is the IPv6 localhost address.
    window.location.hostname === "[::1]" ||
    // 127.0.0.0/8 are considered localhost for IPv4.
    window.location.hostname.match(
      /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
    )
);

export const environments = {
  dev: "develop",
  prod: "prod",
};
