import React from 'react'
import { StyledButton } from './styles'
import { oneOfType, arrayOf, node, shape, oneOf, string } from 'prop-types'
import colours from 'assets/colours'

const Button = ({ children, variant, ...rest }) => (
  <StyledButton variant={variant} {...rest}>
    {children}
  </StyledButton>
)
export default Button

Button.defaultProps = {
  variant: 'zltoLightBlue',
  rest: {},
  type: 'button',
}

Button.propTypes = {
  variant: oneOf(['', ...Object.keys(colours)]),
  children: oneOfType([arrayOf(node), node]).isRequired,
  type: string,
  rest: shape({}),
}
