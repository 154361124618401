/** reference: https://css-tricks.com/snippets/css/media-queries-for-standard-devices **/

import { css } from "styled-components";

export const devices = {
  mobile: "mobile",
  iPad: "iPad",
  iPadPro: "iPadPro",
  phone: "phone",
};

/**
 * Mobile encapsulates smallest phone to iPadPro
 * iPad & iPadPro widths provide more specific control
 */
export const deviceWidths = {
  iPad: {
    both: { minW: "768px", maxW: "1024px" },
    portrait: { minW: "768px", maxW: "1024px" },
    landscape: { minW: "768px", maxW: "1024px" },
  },
  iPadPro: {
    both: { minW: "1024px", maxW: "1366px" },
    portrait: { minW: "1024px", maxW: "1024px" },
    landscape: { minW: "1366px", maxW: "1366px" },
  },
  mobile: {
    both: { minW: "0px", maxW: "1366px" },
    portrait: { minW: "0px", maxW: "1024px" },
    landscape: { minW: "568px", maxW: "1366px" },
  },
  phone: {
    both: { minW: "0px", maxW: "823px" },
    portrait: { minW: "0px", maxW: "450px" },
    landscape: { minW: "568px", maxW: "823px" },
  },
};

/**
 * pass device and styles to get media queries
 * @param {object} styles to apply in query
 * @param {string} device mobile | iPad | iPadPro. Defaults to mobile
 */
const queries = {
  both: (styles, device = "mobile") => {
    const { minW, maxW } = { ...deviceWidths[device].both };
    return css`
      @media only screen and (min-width: ${minW}) and (max-width: ${maxW}) {
        ${styles}
      }
    `;
  },
  portrait: (styles, device = "mobile") => {
    const { minW, maxW } = { ...deviceWidths[device].portrait };
    return css`
      @media only screen and (min-width: ${minW}) and (max-width: ${maxW}) and (orientation: portrait) {
        ${styles}
      }
    `;
  },
  landscape: (styles, device = "mobile") => {
    const { minW, maxW } = { ...deviceWidths[device].landscape };
    return css`
      @media only screen and (min-width: ${minW}) and (max-width: ${maxW}) and (orientation: landscape) {
        ${styles}
      }
    `;
  },
};

/* ----------- Exact widths & heights ----------- */
export const exactQueries = {
  both: (styles, w = null, h = null) => {
    if (w && h)
      return css`
        @media only screen and (width: ${w}) and (height: ${h}) {
          ${styles}
        }
      `;
    else if (w && !h)
      return css`
        @media only screen and (width: ${w}) {
          ${styles}
        }
      `;
    else if (h && !w)
      return css`
        @media only screen and (height: ${h}) {
          ${styles}
        }
      `;
    else return css``;
  },

  portrait: (styles, w = null, h = null) => {
    if (w && h)
      return css`
        @media only screen and (width: ${w}) and (height: ${h}) and (orientation: portrait) {
          ${styles}
        }
      `;
    else if (w && !h)
      return css`
        @media only screen and (width: ${w}) and (orientation: portrait) {
          ${styles}
        }
      `;
    else if (h && !w)
      return css`
        @media only screen and (height: ${h}) and (orientation: portrait) {
          ${styles}
        }
      `;
    else return css``;
  },

  landscape: (styles, w = null, h = null) => {
    if (w && h)
      return css`
        @media only screen and (width: ${w}) and (height: ${h}) and (orientation: landscape) {
          ${styles}
        }
      `;
    else if (w && !h)
      return css`
        @media only screen and (width: ${w}) and (orientation: landscape) {
          ${styles}
        }
      `;
    else if (h && !w)
      return css`
        @media only screen and (height: ${h}) and (orientation: landscape) {
          ${styles}
        }
      `;
    else return css``;
  },
};

export default queries;

export const minQuery = {
  both: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].both.minW;
    return css`
      @media only screen and (min-width: ${w}) {
        ${styles}
      }
    `;
  },
  portrait: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].portrait.minW;
    return css`
      @media only screen and (min-width: ${w}) and (orientation: portrait) {
        ${styles}
      }
    `;
  },
  landscape: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].landscape.minW;
    return css`
      @media only screen and (min-width: ${w}) and (orientation: landscape) {
        ${styles}
      }
    `;
  },
};

export const maxQuery = {
  both: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].both.maxW;
    return css`
      @media only screen and (max-width: ${w}) {
        ${styles}
      }
    `;
  },
  portrait: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].portrait.maxW;
    return css`
      @media only screen and (max-width: ${w}) and (orientation: portrait) {
        ${styles}
      }
    `;
  },
  landscape: (styles, width = null, device = "mobile") => {
    const w = width || deviceWidths[device].landscape.maxW;
    return css`
      @media only screen and (max-width: ${w}) and (orientation: landscape) {
        ${styles}
      }
    `;
  },
};
