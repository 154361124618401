import { Container } from "unstated";
import CookieStorage from "js-cookie";
import { AUTH_DATA } from "constants/authConstants";
import api from "api";
import user from "./user";
import { errorNotification } from "utils/notifications";
import { navigate } from "@reach/router";
import routes from "utils/absoluteRoutes";

const DEFAULT_STATE = {
  token: null,
  expiresAt: null,
  refreshToken: null,
};

class Auth extends Container {
  constructor() {
    super();

    const { token, expiresAt, refreshToken } =
      CookieStorage.getJSON(AUTH_DATA) || DEFAULT_STATE;

      console.log('@@@ token from CookieStorage: ', token);

    // if (new Date(expiresAt) < new Date()) {
    //   this.set(DEFAULT_STATE);
    // } else {
      this.state = {
        token,
        expiresAt,
        refreshToken,
        timeoutId: null // this is explicitly _not_ persisted across page loads
      };
      // this.startTokenTimeout(expiresAt);
    // }
  }

  set = (data = {}) => {
    const updatedState = {
      ...this.state,
      ...data,
    };
    return this.setState(updatedState, () => {
      CookieStorage.set(AUTH_DATA, data);
    });
  };

  clear() {
    this.clearTokenTimeout();
    return this.setState(DEFAULT_STATE, () => {
      CookieStorage.set(AUTH_DATA, DEFAULT_STATE);
    });
  }

  startTokenTimeout = (expiresAt) => {
    const duration = (new Date(expiresAt) - new Date()) - 5 * 1000; // 5 seconds before the timeout
    const id = setTimeout(this.refreshToken, duration);
    this.setState({ timeoutId: id })
  }

  clearTokenTimeout = () => clearTimeout(this.state.timeoutId);

  refreshToken = async () => {
    try {
      const userId = user.state.id;
      const { data } = await api.auth.session({
        userId,
        refreshToken: this.state.refreshToken,
      });
      const { token, refreshToken, expiresAt } = { ...data };
      await this.set({ token, refreshToken, expiresAt });
      await user.set({ ...data.user });
      this.clearTokenTimeout();
      this.startTokenTimeout(expiresAt);
    } catch (err) {
      errorNotification("Failed to refresh session");
      this.logout();
    }
  };

  logout = async () => {
    await user.clear();
    await this.clear();
    navigate(routes.auth.login);
  };
}

export default new Auth();
