import challenges from "./challenges";
import workExperience from "./workExperience";
import impactTasks from "./impactTasks";
import education from "./education";
import credentials from "./credentials";
import organisations from "./organisations";
import skills from "./skills";

export default function (instance) {
  return {
    challenges: challenges(instance),
    workExperience: workExperience(instance),
    impactTasks: impactTasks(instance),
    education: education(instance),
    credentials: credentials(instance),
    organisations: organisations(instance),
    skills: skills(instance),
  };
}
