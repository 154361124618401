export default function (instance) {
  return {
    addSession(parent_partner_id, partner_id, user_uuid, payload) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}`)
        .child('mentorship_sessions')
        .push(payload);
    },
    getSessions(parent_partner_id, partner_id, user_uuid) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}/mentorship_sessions`);
    },
    setSessionOptInCode(parent_partner_id, partner_id, user_uuid, opt_in_code) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}`)
        .child('mentorship_session_opt_in_code')
        .set(opt_in_code)
    },
    getSessionOptInCode(parent_partner_id, partner_id, user_uuid) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}/mentorship_session_opt_in_code`)
    },
  };
}
