const routes = {
  auth: {
    login: "/auth/login",
    createAccount: "/auth/create-account",
    checkEmail: "/auth/check-email",
    signIn: "/auth/sign-in",
    cartedo: "/auth/cartedo",
    forgotPassword: "/auth/forgot-password",
    resetPassword: "/auth/reset-password",
    createPassword: "/auth/create-password",
  },
  mentor: '/mentor',
  cartedoCertificate: "/cartedo-certificate",
  digitalCV: "/digital-cv",
  bizProfile: "/biz-profile",
  marketplace: "/marketplace",
  opportunities: "/opportunities",
  surveys: {
    view: "/surveys",
    submission: "/surveys/submission",
  },
  learn: {
    view: "/learn",
    submission: "/learn/submission",
  },
  redirects: {
    notFound: "/not-found",
  },
  settings: "/settings",
};

export default routes;
