import styled, { css } from "styled-components";
import { colours } from "assets/colours";
import { fonts } from "assets/fontStyles";

const StyledButton = styled.button`
  ${fonts.button}
  height: 3.125em;
  border-radius: 3.75em;
  border: none;
  background: ${colours.primary};
  color: ${colours.white};
  cursor: pointer !important;

  &:focus,
  &:active {
    outline: none;
  }

  &:disabled {
    opacity: 0.5;
  }

  ${({ variant, badge }) =>
    variant &&
    css`
      background: ${colours[variant]};
      color: ${colours.white};

      ${({ badge }) =>
        badge &&
        css`
          /** 26 is hex code for opacity 0.15 */
          background: ${colours[variant] + "26"};
          border: 0.1em solid ${colours[variant]};
          color: ${colours[variant]};
        `}
    `}
`;

export { StyledButton };
