export default function (instance) {
  return {
    getById(userId, credentialId, fileName, config = {}) {
      return instance.get(
        `users/${userId}/credentials/${credentialId}/certificate?fileName=${fileName}>`,
        config
      );
    },
    create(userId, credentialId, body, config = {}) {
      return instance.post(
        `users/${userId}/credentials/${credentialId}/certificate`,
        body,
        {
          ...config,
          headers: {
            "content-type": "multipart/form-data",
          },
        }
      );
    },
  };
}
