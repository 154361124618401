import axios from "axios";
import firebase from './firebase';
import nprogress from "nprogress";
import { ENV } from "utils/environment";
import auth from "./auth";
import authContainer from "containers/auth";
import users from "./users";
import digitalCV from "./routes/digital-cv";

// zlto endpoints
import tasks from "./tasks";
import stores from "./stores";
import surveys from "./surveys";

// esd firebase endpoints
import business from "./business";
import mentor from "./mentor";

import { errorNotification } from "utils/notifications";

const FALLBACK_MESSAGE = "Unable to reach server";
const REFRESH_TOKEN_FAILED_MESSAGE = "Unable to refresh your session";
const UNAUTHORIZED_STATUS_CODE = 401;

const instance = axios.create({
  // TODO: set this to prod Zlto for now
  // baseURL: ENV.BASE_URL,
  baseURL: 'https://api.zlto.co/',
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  async (request) => {
    nprogress.start();
    console.log('@@@ auth.Container.state.token: ', authContainer?.state?.token);
    request.headers = {
      ...request.headers,
      "Content-Type": "application/json",
      Authorization: `Bearer ${authContainer.state.token}`,
    };
    return request;
  },
  async (error) => {
    nprogress.done();
    return error;
  }
);

instance.interceptors.response.use(
  async (response) => {

    nprogress.done();
    console.log('@@@ response success: ', response.data);
    return response.data;
  },
  async ({ response }) => {

    console.log('@@@ response error: ', JSON.stringify(response));

    // if (response?.status === UNAUTHORIZED_STATUS_CODE) {
    //   nprogress.done();
    //   authContainer.logout();
    //   return Promise.reject(REFRESH_TOKEN_FAILED_MESSAGE);
    // }
    nprogress.done();
    // errorNotification(
    //   (response && response.data?.meta?.message) || FALLBACK_MESSAGE
    // );
    return Promise.reject(
      (response) || FALLBACK_MESSAGE
    );
  }
);

export default {
  auth: auth(instance),
  users: users(instance),
  tasks: tasks(instance),
  surveys: surveys(instance),
  stores: stores(instance),
  digitalCV: digitalCV(instance),
  business: business(firebase),
  mentor: mentor(firebase),
};
