const password = (instance) => {
  return {
    create(userId, body, config = {}) {
      return instance.post(`users/${userId}/password`, body, config);
    },

    edit(userId, body, config = {}) {
      return instance.patch(`users/${userId}/password`, body, config);
    },
  };
};

export default password;
