import _ from 'lodash';

export default function (instance) {
  return {
    get(parent_partner_id, partner_id, user_uuid, config = {}) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}`)
    },
    setRequirement(parent_partner_id, partner_id, user_uuid, type, payload, config = {}) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}`)
        .child(`business_requirements/${type}`)
        .set(payload);
    },
    setPersonalDetails(parent_partner_id, partner_id, user_uuid, payload, config = {}) {
      return instance
        .database()
        .ref(`business_partners/${parent_partner_id}/program_partners/${partner_id}/users/${user_uuid}`)
        .child(`personal_details`)
        .set(payload);
    },
  };
}
