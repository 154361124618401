export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get('report/earn/surveys/', config);
    },
    getSurveyDetails(questionnaire_id, config = {}) {
      return instance.get(`earn/survey/${questionnaire_id}/`, config);
    },
    getUserAnswers(questionnaire_id, client_uuid, config = {}) {
      return instance.get(`report/earn/survey/answers/${questionnaire_id}/user/${client_uuid}/`, config);
    },
    submitAnswer(body, config = {}) {
      console.log('@@@@ submitting answer with body: ', body);

      return instance.post(`earn/survey/`, body, {
        headers: {
          'Content-Type': 'multipart/form-data',
        }
      });
    },
  };
}
