import { errorNotification } from "utils/notifications";

export default function (instance) {
  return {
    create(userId, photo, config = {}) {
      return instance
        .post(`users/${userId}/photo`, photo, {
          ...config,
          headers: {
            "content-type": "multipart/form-data",
          },
        })
        .catch((err) => {
          errorNotification(
            "An error occurred while trying to save your photo. Please try again or upload a different photo."
          );
        });
    },
  };
}
