export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get("skills");
    },
    getKeyNames(config = {}) {
      return instance.get("skills/names");
    },
    getById(skillId, config = {}) {
      return instance.get(`skills/${skillId}`);
    },

    /**
     * Create skill. Will need an organisation to create.
     * @param {object} body { name }
     * @param {*} config
     */
    create(body, config = {}) {
      return instance.post("skills", body, config);
    },
  };
}
