export default function (instance) {
  return {
    getAll(createdByAdmin = false, config = {}) {
      return instance.get("assignments", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getKeyNames(createdByAdmin = false, config = {}) {
      return instance.get("assignments/names", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getById(assignmentId, config = {}) {
      return instance.get(`assignments/${assignmentId}`);
    },

    /**
     * Create impact task. Will need an organisation to create.
     * @param {object} body { name, description, organisationId, url, skills }
     * @param {*} config
     */
    create(body, config = {}) {
      return instance.post("assignments", body, config);
    },
  };
}
