import React from "react";
import {
  Container,
  ButtonWrapper,
  TextWrapper,
  LeftWrapper,
  MainHeading,
  Subheading,
  LogoStyle,
  Header,
} from "./styles";

import routes from "utils/absoluteRoutes";
import { navigate } from "@reach/router";
import Button from "components/button";

const Error = () => (
  <Container>
    <Header>
      <LogoStyle src={require("assets/images/zlto_logo_white.svg")} />
    </Header>
    <LeftWrapper>
      <TextWrapper>
        <MainHeading>Oops!</MainHeading>
        <Subheading>Something went wrong.</Subheading>
      </TextWrapper>
      <ButtonWrapper>
        <Button
          variant='zltoLightBlue'
          onClick={() => {
            navigate(routes.bizProfile);
            window.location.reload();
          }}
        >
          Return to my business
        </Button>
      </ButtonWrapper>
    </LeftWrapper>
  </Container>
);

export default Error;
