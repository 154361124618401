export default function (instance) {
  return {
    getAll(userId, config = {}) {
      return instance.get(`users/${userId}/skills`, config);
    },
    create(userId, body, config = {}) {
      return instance.post(`users/${userId}/skills`, body, config);
    },
  };
}
