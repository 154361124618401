export default function (instance) {
  return {
    getAll(createdByAdmin = false, config = {}) {
      return instance.get("challenges", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getKeyNames(createdByAdmin = false, config = {}) {
      return instance.get("challenges/names", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getById(challengeId, config = {}) {
      return instance.get(`challenges/${challengeId}`);
    },

    /**
     * Create challenge. Will need an organisation to create.
     * @param {object} body { name, description, organisationId, url, skills }
     * @param {*} config
     */
    create(body, config = {}) {
      return instance.post("challenges", body, config);
    },
  };
}
