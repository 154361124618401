import React from "react";
import * as Sentry from "@sentry/browser";
import { Router } from "@reach/router";
import AppRoutes from "pages/routes";
import Redirect from "components/redirect";
import NotFound from "pages/not-found";
import loadable from "@loadable/component";
import routes from "utils/absoluteRoutes";
import Error from "components/error-boundary";
import { isLocalHost } from "utils/environment";
import 'antd/dist/antd.css'

const Authentication = loadable(() => import("pages/authentication"));

const App = () => {
  return (
    <ErrorBoundary>
      <Router className="router">
        <Redirect default to={routes.redirects.notFound} />
        <Redirect path="/" to={routes.bizProfile} />
        <Authentication path="auth/*" />
        <AppRoutes path="/*" />
        <NotFound path="not-found" />
      </Router>
    </ErrorBoundary>
  );
};
export default App;

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service

    if (!isLocalHost) {
      Sentry.withScope((scope) => {
        scope.setExtras(errorInfo);
        const eventId = Sentry.captureException(error);
        this.setState({ eventId });
      });
    } else {
      console.error(error.stack || error);
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      //TODO: put custom Error page
      return <Error />;
    }
    return this.props.children;
  }
}
