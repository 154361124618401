import React, { useEffect } from "react";
import { Router, useLocation, redirectTo } from "@reach/router";
import loadable from "@loadable/component";
import Redirect from "components/redirect";
import auth from "containers/auth";
import routes from "utils/absoluteRoutes";

const BizProfile = loadable(() => import("pages/biz-profile"));
const Marketplace = loadable(() => import("pages/marketplace"));
const Opportunities = loadable(() => import("pages/opportunities"));
const Surveys = loadable(() => import("pages/surveys"));
const Learn = loadable(() => import("pages/learn"));
const CartedoCertificate = loadable(() => import("pages/cartedo-certificate"));
const Mentor = loadable(() => import("pages/mentor"));
const Settings = loadable(() => import("pages/settings"));

const AppRoutes = () => {
  const currentLocation = useLocation().pathname;

  useEffect(() => {
    if (!auth.state.token || auth.state.expiresAt < new Date()) {
      redirectTo(routes.auth.login);
    } else {
      return;
    }
  }, [currentLocation]);

  return (
    <Router className="router">
      <Redirect default to={routes.redirects.notFound} />
      <CartedoCertificate path="cartedo-certificate/*" />
      <Marketplace path="marketplace/*" />
      <Opportunities path="opportunities/*" />
      <Surveys path="surveys/*" />
      <Learn path="learn/*" />
      <BizProfile path="biz-profile/*" />
      <Settings path="settings" />
      <Mentor path="mentor/*" />
    </Router>
  );
};

export default AppRoutes;
