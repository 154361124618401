import styled from 'styled-components'
import Background from 'assets/images/404-desktop.svg'
import MobileBackground from 'assets/images/not-found-mobile-background.svg'
import queries, { devices } from 'utils/responsivenessHelper'
import { StyledButton } from 'components/button/styles'
import colours from 'assets/colours'

const Container = styled.div`
  width: 100vw;
  height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  background-image: url(${Background});
  background-size: cover;
  background-repeat: no-repeat;
  background-color: #f9ab3e;
  vertical-align: middle;

  ${queries.portrait(
    {
      width: '100vw',
      backgroundPosition: '-60vw',
      alignItems: 'center',
      justifyContent: 'center',
    },
    devices.iPadPro
  )}

  ${queries.portrait(
    {
      width: '100vw',
      backgroundPosition: '-50vw',
      alignItems: 'center',
      justifyContent: 'center',
    },
    devices.iPad
  )}

  @media screen and (max-width: 600px) {
    background-image: url(${MobileBackground});
    justify-content: unset;
    align-items: unset;
  }
`

const Header = styled.div`
  width: 100%;
  height: 151px;
  justify-content: left;
  align-content: left;

  @media screen and (max-width: 600px) {
    height: 10%;
  }
`

const LogoStyle = styled.img`
  margin: 2% 0 0 2%;
  @media screen and (max-width: 600px) {
    width: 30%;
    margin: 3% 0 0 4%;
  }
`

const LeftWrapper = styled.div`
  width: calc(200px + 35%);
  justify-content: left;
  align-content: left;
  margin: 5% 0 auto -30%;

  ${queries.portrait(
    {
      margin: '20% 0 auto -30%',
    },
    devices.iPadPro
  )}

  ${queries.portrait(
    {
      margin: '20% 0 auto -20%',
    },
    devices.iPad
  )}

  @media screen and (max-width: 600px) {
    width: 80%;
    justify-content: center;
    text-align: center;
    margin: 10% auto 0 auto;
  }
`

const TextWrapper = styled.div`
  width: 100%;
`

const ButtonWrapper = styled.div`
  width: 100%;

  ${StyledButton} {
    transition: all, 0.3s;
    padding: 2% 5%;
    &:hover {
      cursor: pointer;
      padding: 2% 15%;
      background-color: ${colours.lightBlue};
    }

    ${queries.portrait(
      {
        width: '75%',
        fontSize: 'calc(20px + 1vw)',
        padding: '1em 1em 1em 1em',
      },
      devices.iPadPro
    )}

    ${queries.portrait(
      {
        width: '65%',
        fontSize: 'calc(16px + 0.4vw)',
        padding: '1em 1em 1em 1em',

      },
      devices.iPad
    )}
  }
`

const MainHeading = styled.h1`
  color: #fff;
  font-size: 6.5em;
  letter-spacing: 0.02em;
  font-weight: bold;
  margin: 0 0 5% 0;

  ${queries.portrait(
    {
      fontSize: 'calc(80px + 4.6vw)',
    },
    devices.iPadPro
  )}

  @media screen and (max-width: 600px) {
    font-size: calc(60px + 3vw);
    margin: 0 auto 10% auto;
  }
`

const Subheading = styled.h3`
  color: #fff;
  letter-spacing: 0.02em;
  font-size: 1.8em;
  font-weight: 600;
  width: 60%;
  margin: 0 0 4% 0;

  ${queries.portrait(
    {
      fontSize: 'calc(29px + 1.4vw)',
      width: '80%',
      margin: '0 0 10% 2%;',
    },
    devices.iPadPro
  )}

  @media screen and (max-width: 600px) {
    font-size: calc(14px + 2vw);
    width: 100%;
    margin: 0 auto 14% auto;
  }
`

export {
  Container,
  ButtonWrapper,
  TextWrapper,
  LeftWrapper,
  Subheading,
  MainHeading,
  Header,
  LogoStyle,
}
