export default function (instance) {
  return {
    getAll(createdByAdmin = false, config = {}) {
      return instance.get("qualifications", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getKeyNames(createdByAdmin = false, config = {}) {
      return instance.get("qualifications/names", {
        ...config,
        params: createdByAdmin ? { createdByAdmin: true } : {},
      });
    },
    getById(qualificationId, config = {}) {
      return instance.get(`qualifications/${qualificationId}`);
    },

    /**
     * Create educational record. Will need an organisation to create.
     * @param {object} body { name, description, organisationId, url, skills }
     * @param {*} config
     */
    create(body, config = {}) {
      return instance.post("qualifications", body, config);
    },
  };
}
