export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get('store/stores/?family=True/', config);
    },
    purchase(itemId, config = {}) {
      return instance.get(`store/purchase/${itemId}/`, config);
    },
    getHistory(config = {}) {
      return instance.get('store/transaction/coupon/list/' + '?nocache=' + new Date().getTime(), config);
    },
  };
}
