import credentials from "./credentials";
import client from "./client";
import skills from "./skills";
import products from "./products";
import zltoWallet from "./zlto-wallet";
import transactions from "./transactions";
import password from "./password";
import photo from "./photo";

export default function (instance) {
  return {
    edit(userId, body, config = {}) {
      return instance.patch(`users/${userId}`, body, config);
    },

    getById(userId, config = {}) {
      return instance.get(`users/${userId}`, config);
    },

    // Zlto endpoints
    client: client(instance),

    credentials: credentials(instance),
    skills: skills(instance),
    products: products(instance),
    zltoWallet: zltoWallet(instance),
    transactions: transactions(instance),
    password: password(instance),
    photo: photo(instance),
  };
}
