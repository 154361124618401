import certificates from "./certificates";

export default function (instance) {
  return {
    getByType(userId, type, config = {}) {
      return instance.get(`users/${userId}/credentials?type=${type}`, config);
    },

    getById(userId, credentialId, config = {}) {
      return instance.get(
        `users/${userId}/credentials/${credentialId}`,
        config
      );
    },
    create(userId, body, config = {}) {
      return instance.post(`users/${userId}/credentials`, body, config);
    },
    certificates: certificates(instance),
  };
}
