export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get("jobs", config);
    },
    getById(jobId, config) {
      return instance.get(`jobs/${jobId}`, config);
    },
    /**
     * Create work experience
     * @param {object} body { "title": "string", "description": "string", "organisationId": "3fa85f64-5717-4562-b3fc-2c963f66afa6", "skillNames": [ "string" ] }
     * @param {object} config
     */
    create(body, config = {}) {
      return instance.post("jobs", body, config);
    },
  };
}
