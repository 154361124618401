import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCjNyBI009tf-vIu3oZdZmkcuK_iZRgsg0",
  authDomain: "zlto-esd.firebaseapp.com",
  databaseURL: "https://zlto-esd.firebaseio.com",
  projectId: "zlto-esd",
  storageBucket: "zlto-esd.appspot.com",
  messagingSenderId: "24624523300",
  appId: "1:24624523300:web:bdb08332d1c46ff0e1d0b1"
};

firebase.initializeApp(config);
// firebase.analytics();

const storage = firebase.storage();
const db = firebase.firestore();

export {
 storage,
 db,
 firebase as default
};
