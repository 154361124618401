export default function (instance) {
  return {
    getAll(config = {}) {
      return instance.get("organisations");
    },
    getKeyNames(config = {}) {
      return instance.get("organisations/names");
    },
    getById(organisationId, config = {}) {
      return instance.get(`organisations/${organisationId}`);
    },
    /**
     * Create organisation.
     * @param {object} body { "name": "string", "url": "string", "logoURL": "string", "primaryContactName": "string", "primaryContactEmail": "user@example.com" }
     * @param {*} config
     */
    create(body, config = {}) {
      return instance.post("organisations", body, config);
    },
  };
}
